<template>
  <div class="repair-form-contaner">
    <Header></Header>
    <Nav></Nav>
    <Layout>
      <!-- 头部s -->
      <el-row class="pb16" type="flex" align="middle" justify="space-between">
        <div class="left f22 c3">报修报表</div>
        <div class="right">
          <button @click="toDevice">
            <img src="@/assets/images/home/return.png" alt="" />
            <span class="f14 c6">返回</span>
          </button>
        </div>
      </el-row>
      <div class="line"></div>
      <!-- 头部e -->
      <!-- 表单部分 s -->
      <el-form
        label-width="104px"
        :model="ruleForm"
        :status-icon="false"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <!--联系地址： -->
        <el-form-item label="联系地址：" required>
          <div @click="dialogShow = true">
            <el-row
              class="add-box"
              type="flex"
              align="middle"
              justify="space-between"
            >
              <el-button type="text">
                <div class="text c3 f14" v-if="Object.keys(showAdd).length">
                  <p>
                    {{ showAdd.contactsName + " " + showAdd.contactsPhone }}
                  </p>
                  <p>
                    {{
                      showAdd.provinceName +
                      showAdd.cityName +
                      showAdd.areaName +
                      showAdd.address
                    }}
                  </p>
                </div>
                <div class="text c3 f14" v-else>
                  <p>暂无地址</p>
                  <p>请去新增</p>
                </div>
              </el-button>

              <div class="img-box">
                <img src="@/assets/images/home/right.png" alt="" />
              </div>
            </el-row>
          </div>
        </el-form-item>
        <!-- 联系地址：结束 -->
        <!-- 维保内容: -->
        <el-form-item label="故障现象:" required prop="content">
          <el-input
            type="textarea"
            v-model="ruleForm.content"
            placeholder="请输入故障现象"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 维保内容:结束 -->
        <!-- 备注开始 -->
        <el-form-item class="mark-box">
          <button
            v-for="(item, index) in tagData"
            :key="index"
            class="mark c6 f14"
            :class="{ active: activeTag.includes(index) }"
            @click.prevent="tagHandle(index, item)"
          >
            {{ item.tagName }}
          </button>
          <!-- <button
            class="mark c6 f14"
            :class="{ active: tool === 1 }"
            @click.prevent="tool = tool === 1 ? 0 : 1"
          >
            自备工具
          </button>
          <button
            class="mark c6 f14"
            :class="{ active: resident === 1 }"
            @click.prevent="resident = resident === 1 ? 0 : 1"
          >
            需要驻场
          </button>
          <button
            class="mark c6 f14"
            :class="{ active: travelCost === 1 }"
            @click.prevent="travelCost = travelCost === 1 ? 0 : 1"
          >
            承担差旅
          </button> -->
          <!-- <button
            class="mark c6 f14"
            :class="{ active: active == 1 }"
            @click.prevent="changeActive(1)"
          >
            自备工具
          </button>
          <button
            class="mark c6 f14"
            :class="{ active: active == 2 }"
            @click.prevent="changeActive(2)"
          >
            需要驻场
          </button>
          <button
            class="mark c6 f14"
            :class="{ active: active == 3 }"
            @click.prevent="changeActive(3)"
          >
            承担差旅
          </button> -->
        </el-form-item>
        <!-- 备注结束 -->
        <!-- 图片开始 -->
        <el-form-item class="mt40" label="图片/视频上传:">
          <!-- <el-upload
            action=""
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :file-list="fileList"
            :auto-upload="false"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="ruleForm.dialogImageUrl" alt="" />
          </el-dialog> -->
          <el-upload
            :action="objData.host"
            :data="objData"
            multiple
            :limit="3"
            list-type="picture-card"
            :before-upload="beforeUploadFun"
            :on-progress="progressFun"
            :on-remove="handleRemove"
            :on-success="videoSuccess"
            :on-change="handleChange"
            :file-list="fileList"
          >
            <!-- <i class="el-icon-plus" v-if="showAddSource"></i> -->
            <div slot="trigger" class="add-upload-box" v-if="showAddSource">
              <i class="el-icon-plus"></i>
            </div>
            <!-- <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                  > -->
          </el-upload>
          <el-dialog :visible.sync="videodialogVisible" :append-to-body="true">
            <video
              id="upvideo"
              width="100%"
              autoplay
              height="500"
              :src="dialogImageUrl"
              controls
            ></video>
          </el-dialog>
        </el-form-item>
        <!-- 图片结束 -->
        <!-- 提交开始 -->
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
      <!-- 表单 e -->
    </Layout>
    <Footer></Footer>
    <!-- 对话框 -->
    <el-dialog
      top="0"
      :visible.sync="dialogShow"
      width="600px"
      :before-close="handleClose"
      :show-close="false"
      custom-class="dialog-box"
    >
      <!-- 头部 -->
      <el-row
        class="header p32"
        type="flex"
        justify="space-between"
        align="center"
      >
        <div class="left">
          <img src="@/assets/images/recovery/line.png" alt="" />
          <span class="c16 f3">选择我的地址</span>
        </div>
        <div class="right" @click="dialogShow = false">
          <img src="@/assets/images/recovery/close.png" alt="" />
        </div>
      </el-row>
      <!-- 内容 -->
      <div class="content b">
        <!-- 1 -->
        <div
          class="item"
          v-for="(item, index) in addList"
          :key="index"
          @click="changeAddress(index, item)"
        >
          <img
            v-if="whichAddress == index"
            src="@/assets/images/addselect.png"
            alt=""
          />
          <img
            src="@/assets/images/addnoselect.png"
            v-if="whichAddress != index"
            alt=""
          />
          <div class="item-content">
            <p class="line1">
              <span>{{ item.contactsName }}</span>
              <span>{{ item.contactsPhone }}</span>
            </p>
            <p class="line2">
              {{
                item.provinceName +
                " " +
                item.cityName +
                " " +
                item.areaName +
                " " +
                item.address +
                " "
              }}
            </p>
          </div>
        </div>
        <!-- 2 -->
        <!-- <div class="item" @click="changeAddress(2)">
          <img
            v-if="whichAddress == 2"
            src="@/assets/images/home/correct.png"
            alt=""
          />
          <img
            src="@/assets/images/home/no-correct.png"
            v-if="whichAddress != 2"
            alt=""
          />
          <div class="item-content">
            <p class="line1">
              <span>蔡小红</span>
              <span>13838383388</span>
            </p>
            <p class="line2">河南省郑州市二七区大学路华城国际中心</p>
          </div>
        </div> -->
        <!-- 新增 -->
      </div>
      <div class="content2">
        <p class="add-address f14 mt20 p">
          <span @click="toAddPod">+新增地址</span>
          <!-- <router-link :to="{ name: 'addpos' }"></router-link> -->
        </p>
        <div class="btn-box mt20">
          <button class="cancle" @click="btnCancle">取消</button>
          <button class="confirm" @click="btnConfirm">确认</button>
        </div>
      </div>
    </el-dialog>
    <!-- 新增地址弹窗结束 -->
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="460px"
      :show-close="false"
      class="assderTc-repairform"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">提交成功</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p class="text">
          您已成功提交报修订单请耐心等待平台安排服务人员与您联系
        </p>
        <div class="con-box">
          <button class="btn" @click="confirm">确认</button>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisibleTwo"
      width="460px"
      :show-close="false"
      class="assderTc-repairform"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">提交成功</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisibleTwo = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p class="text">
          您已成功提交意向清单请耐心等待平台安排服务人员与您联系
        </p>
        <div class="btn-box">
          <button class="btn" @click="confirmTwo">确认</button>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
    <!-- 右侧保修 客服 -->
    <!-- <el-row class="fix-right">
      <el-row class="item2">
        <el-button type="text" @click="bassderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row> -->
    <!-- 弹窗 start -->
    <!-- <el-dialog
      top="0"
      :visible.sync="bassderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="bassderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>13712345678</span>
        </p>
      </div>
    </el-dialog> -->
    <!-- 弹窗结束 -->
    <tell-us bottom="266px"></tell-us>
  </div>
</template>

<script>
let flag = true;
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
export default {
  name: "YoufujichuangRepairform",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    var validateContent = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入维保内容"));
      }
      callback();
    };
    return {
      bassderVisible: false, //客服弹窗
      dialogVisible: false,
      active: 1, // 激活哪个按钮
      assderVisible: false, //成功弹窗
      whichAddress: 0, //选择哪个地址
      dialogShow: false, //对话框
      fileList: [
        {
          name: "1",
          url: "https://dummyimage.com/100X100/000/fff",
        },
        {
          name: 1,
          url: "https://dummyimage.com/100X100/000/fff",
        },
      ],
      ruleForm: {
        content: "",
      },
      rules: {
        content: [{ validator: validateContent, trigger: ["blur", "change"] }],
      },
      addList: [], //所有地址
      addId: "", //地址id

      resident: 0, // 是否驻场 0-否  1-是
      tool: 0, // 是否自备工具 0-否  1-是
      travelCost: 0, // 是否承担差旅 0-否  1-是
      showAdd: {}, //展示地址信息
      selectAddId: "", //选中地址id
      assderVisibleTwo: false, //第二个确认弹窗

      // 问题描述标签
      tagData: [], //问题描述数据
      activeTag: [],
      activeIds: [],

      id: "",

      // 图片上传

      fileList: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏

      companyId: "",
    };
  },

  mounted() {},
  created() {
    this.tagList();

    this.id = this.$route.query.id;
    this.companyId = this.$route.query.companyId;

    // 查询地址
    this.getAllPosById();

    // 标签-列表查询
    let data = this.$store.state.FormData;
    console.log(data, "ppp");
    if (!data) {
      return;
    }
    if (data.selectAddId) {
      this.selectAddId = data.selectAddId;
    }
    if (data.upImg) {
      this.upImg = data.upImg;
      this.fileList = this.upImg;
    }
    if (data.content) {
      this.ruleForm.content = data.content;
    }
    if (data.activeIds) {
      this.activeIds = data.activeIds;
      console.log(this.activeIds, "ppp");
    }
    if (data.companyId) {
      this.companyId = data.companyId;
    }
    if (data.imgsUrl) {
      this.upImg = data.imgsUrl;
      this.fileList = this.upImg;
    }
    if (data.videoUrl) {
      this.upVideo = data.videoUrl;
    }
  },

  methods: {
    toAddPod() {
      this.$store.state.FormData = {
        selectAddId: this.selectAddId,
        upImg: this.upImg,
        content: this.ruleForm.content,
        activeIds: this.activeIds,
        companyId: this.companyId,
        imgsUrl: this.upImg,
        videoUrl: this.upVideo,
      };
      this.$router.push({ name: "addpos" });
    },
    // 图片上传方法
    videoSuccess(res, file, fileList) {
      this.findvideocover(file.url, file);
    },

    //截取视频第一帧作为播放前默认图片
    findvideocover(url, file) {
      // const  video = document.getElementById("myvideo"); // 获取视频对象
      const video = document.createElement("video"); // 也可以自己创建video
      // video.innerHTML = "111";
      video.src = url; // url地址 url跟 视频流是一样的

      // var canvas = document.getElementById('mycanvas') // 获取 canvas 对象
      var canvas = document.createElement("canvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧

      video.oncanplay = () => {
        console.log(video.clientWidth, video.clientHeight);
        canvas.width = video.clientWidth ? video.clientWidth : 320; // 获取视频宽度
        canvas.height = video.clientHeight ? video.clientHeight : 320; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 转换成base64形式
        this.videoFirstimgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        file.url = this.videoFirstimgsrc;
        // base64转成bolb文件
        const fileBolb = this.base64toFile(
          this.videoFirstimgsrc,
          this.getFileName(file)
        );
        // 把首图上传生成云上地址
        this.getFirstPngUrl(fileBolb);
        video.remove();
        canvas.remove();
      };
    },

    base64toFile(baseUrl, filename = "file") {
      let arr = baseUrl.split(",");
      let type = arr[0].match(/:(.*?);/)[1]; // 解锁图片类型
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let bufferArray = new Uint8Array(n);
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n);
      }
      return new File([bufferArray], filename, { type });
    },

    getFirstPngUrl(file) {
      const fd = new FormData();
      fd.append("file", file);
      // this.$post为我自己封装的axios的post方法。可直接用axios的post方法
      // this.$axios.post(this.showConText.host, fd).then((res) => {
      //   this.videoFirstimgsrc = res.url;
      //   this.videoWriteFileList[0].url = res.url;
      // });
    },

    getFileName(file) {
      const type = file.raw.type.split("/")[1];
      let name = "";
      if (type) {
        name = file.raw.name.substring(0, file.raw.name.indexOf(`.${type}`));
      }
      return `${name}.png`;
    },
    // handleSuccess(res, file, fileList) {
    // console.log("上传成功了", res, file, fileList);
    // console.log(this.fileList);
    // },

    progressFun() {
      // console.log("正在上传");
    },

    // 图片上传前函数
    beforeUploadFun(file) {
      console.log("上传前函数", file);
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      if (this.upImg.length) {
        // 用户已经不是第一次上传了  判断一定要是图片才可以
        // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
        var fileType = file.type.split("/")[0];
        // let res = imglist.some((item) => {
        //   return item == fileType;
        // });
        // console.log(res, "结果");
        if (fileType != "image") {
          // 上传的不是图片
          // console.log("结果是图片");
          this.$message.info("请继续上传图片");
          this.fileList.pop();
          return false;
        }
      }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });
            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
            }

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }

            if (this.upImg.length == 3) {
              this.showAddSource = false;
            }
            console.log(this.upImg);
            rs(true);
          }
        });
      });
    },

    //根据分组查询系统配置
    getOssConfig() {
      this.$API
        .getOssConfig()
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            // console.log(this.showConText);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 标签点击方法
    tagHandle(index, item) {
      if (this.activeTag.includes(index)) {
        // 如果已经点击一次选中了，再次点击是想去掉
        // console.log("arr", arr);
        this.activeTag = this.activeTag.filter((item) => {
          return item != index;
        });
        this.activeIds = this.activeIds.filter((item) => {
          return item != this.tagData[index].id;
        });
      } else {
        // 第一次点击此标签
        this.activeTag.push(index);
        this.activeIds.push(item.id);
      }
      // console.log(this.activeTag, "activeTag");
      // this.activeTag.push(index);
      // this.activeIds.push(item.id);
      // console.log(this.activeTag, "activeTag");
      // console.log(this.activeIds, "activeIds");
    },

    // 标签-列表查询
    tagList() {
      this.$API
        .tagList()
        .then((res) => {
          if (res.code == 1) {
            this.tagData = res.data;
            // console.log(this.tagData, "aaa");
            this.tagData.forEach((item) => {
              let that = this;
              that.activeIds.forEach((a, b) => {
                if (item.id == a) {
                  console.log(b);
                  this.activeTag.push(b);
                }
              });
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    confirmTwo() {
      this.assderVisibleTwo = false;
      setTimeout(() => {
        this.$router.back();
      }, 300);
    },
    // 根据id查询某个地址
    getOnePos() {
      let paramData = {
        id: this.selectAddId,
      };
      this.$API
        .getOnePos(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.showAdd = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询地址
    getAllPosById() {
      let param = {
        deviceId: Number(this.id),
      };
      this.$API
        .getAllPosById(param)
        .then((res) => {
          if (res.code == 1) {
            // 提交成功
            this.addList = res.data;
            if (res.data.length) {
              // 有地址
              let showAdd = res.data.filter((item) => {
                if (item.isDefault == 1) {
                  return item;
                }
              });

              if (showAdd.length == 0) {
                // 无默认地址
                this.showAdd = res.data[0];
                this.selectAddId = this.showAdd.id;
              } else {
                // 有默认地址
                this.showAdd = showAdd[0];
                this.selectAddId = this.showAdd.id;
              }
              // console.log("showadd", showAdd);
              // console.log("showadd", this.showAdd);
            }
            //将地址展示列表排序 有默认地址的话  默认地址放在第一个

            let defaultAdd = res.data.filter((item) => {
              if (item.isDefault == 1) {
                return item;
              }
            });
            if (defaultAdd.length) {
              // 有默认地址
              let elseAdd = res.data.filter((item) => {
                if (item.isDefault == 0) {
                  return item;
                }
              });
              this.addList = [...defaultAdd, ...elseAdd];
            }
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    //弹窗方法
    bassderHandle() {
      this.assderVisible = false;
    },

    //删除图片
    handleRemove(file, fileList) {
      console.log(file.url, "fileurl");
      console.log(this.fileList, "filelist");
      this.fileList.forEach((item, index) => {
        // console.log(item.url);
        if (item.url == file.url) {
          console.log(index);
          this.fileList.splice(index, 1);
          this.upImg = this.upImg.filter((item, delIndex) => {
            return delIndex != index;
          });

          console.log(this.upImg, "upImg");
          console.log(this.fileList, "filelist");
        }
      });
      this.showAddSource = true;
    },

    //上传图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file);
    },
    // 改变激活按钮
    changeActive(index) {
      this.active = index;
    },
    // 新增成功确定
    confirm() {
      this.assderVisible = false;
      this.dialogShow = false;
      // 更改地址方法
      this.getOnePos();
      // setTimeout(() => {
      //   this.$router.back();
      // }, 800);
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 取消
    btnCancle() {
      this.dialogShow = false;
    },
    // 确认
    btnConfirm(item) {
      // console.log("选中id", this.selectAddId);
      // this.assderVisible = true;

      // this.assderVisible = false;
      this.dialogShow = false;
      // 更改地址方法
      this.getOnePos();
      // setTimeout(() => {
      //   this.$router.back();
      // }, 800);
    },
    // 改变地址
    changeAddress(num, item) {
      this.whichAddress = num;
      this.selectAddId = item.id;
    },
    //对话框处理函数
    handleClose() {
      this.dialogShow = false;
    },
    // 返回
    toDevice() {
      this.$router.push("/device");
    },
    // 提交
    submitForm(formName) {
      if (!flag) {
        return;
      }

      // 地址
      if (!this.selectAddId) {
        this.$message({
          message: "请选择/新增地址",
          type: "warning",
        });
        return;
      }

      // 图片上传
      // 检验是否上传图片
      if (this.upImg.length == 0 && this.upVideo.length == 0) {
        // 如果图片数组长度为零并且视频数组不为零
        this.$message({
          message: "请上传资源",
          type: "warning",
        });
        return;
      }
      if (this.upVideo.length == 0 && this.upImg.length == 0) {
        // 如果视频长度为零并且图片长度也为零
        this.$message({
          message: "请上传资源",
          type: "warning",
        });
        return;
      }
      let imgList = [];
      this.upImg.forEach((item) => {
        imgList.push(item.url);
      });

      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = false;
          // 图片上传参数
          let filelistStr = this.upImg.map((item) => item).join(",");
          // 上传视频参数
          let videolistStr = this.upVideo.map((item) => item).join(",");

          // console.log(filelistStr, "上传图片参数");
          // console.log(videolistStr, "上传视频参数");
          // return;

          // 将标签id数组转为字符串
          let tagId = this.activeIds.join(",");
          // 打开报修成功弹窗
          let params = {
            orderAddrId: this.selectAddId, //地址id
            imgsUrl: imgList, //图片地址
            repairContent: this.ruleForm.content, //维保内容
            tagId,
            deviceId: Number(this.id),
            companyId: this.companyId,
            imgsUrl: filelistStr,
            videoUrl: videolistStr,
          };
          // console.log(params);
          // return;
          this.$API
            .addRepairOrder(params)
            .then((res) => {
              if (res.code == 1) {
                this.assderVisibleTwo = true;
                setTimeout(() => {
                  flag = true;
                }, 800);
                this.$store.state.FormData = {};
                // alert("submit!");
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              console.log(err);
            });
        } else {
          flag = true;
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.repair-form-contaner {
  .add-upload-box {
    width: 83px !important;
    height: 83px !important;
    line-height: 83px !important;
    border: 1px dotted #999;
    position: relative;
    z-index: 99;
  }
  // 右侧保修 客服
  // .fix-right {
  //   cursor: pointer;
  //   width: 88px;
  //   height: 90px;
  //   border: 1px solid #ebebeb;
  //   position: fixed;
  //   z-index: 1000;
  //   right: 0;
  //   // top: 50%;
  //   bottom: 266px;
  //   // transform: translateY(-50%);
  //   .item1 {
  //     background-color: #ffffff;
  //   }
  //   .item2 {
  //     background-color: #ed1b23;
  //   }
  //   .item1,
  //   .item2 {
  //     height: 90px;
  //     text-align: center;
  //     box-sizing: border-box;
  //     padding-top: 9px;
  //     img {
  //       width: 24px;
  //       height: 24px;
  //     }
  //     p {
  //       color: #757575;
  //       font-size: 14px;
  //       margin-top: 12px;
  //     }
  //   }
  //   .item2 p {
  //     color: #ffffff;
  //   }
  // }
  // 自定义弹窗样式
  // .assderTc {
  //   display: flex;
  //   align-items: center;
  //   .title {
  //     padding-top: 32px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: relative;
  //     font-size: 22px;
  //     font-weight: bold;
  //     color: #333333;
  //     img {
  //       width: 32px;
  //       height: 32px;
  //       position: absolute;
  //       top: 32px;
  //       right: 32px;
  //     }
  //   }
  //   ::v-deep .el-dialog__header {
  //     padding: 0;
  //   }
  //   ::v-deep .el-dialog {
  //     border-radius: 0 !important;
  //   }
  //   ::v-deep .el-dialog__body {
  //     padding: 0;
  //   }
  //   ::v-deep .el-dialog__footer {
  //     padding: 0;
  //   }
  //   ::v-deep .el-dialog {
  //     border-radius: 10px;
  //   }
  //   .ja-c {
  //     // height: 311px;
  //     box-sizing: border-box;
  //     // padding-left: 62px;
  //     // padding-top: 20px;
  //     p {
  //       display: flex;
  //       align-items: center;
  //       margin-bottom: 32px;
  //       img {
  //         width: 30px;
  //         height: 30px;
  //       }
  //       span {
  //         font-size: 14px;
  //         color: #333333;
  //         margin-left: 16px;
  //       }
  //     }
  //     .btn {
  //       margin-top: 10px !important;
  //     }
  //   }

  //   .btnad {
  //     width: 112px;
  //     height: 40px;
  //     background: rgba(246, 247, 249, 1);
  //     border: 1px solid #ecedef;
  //     opacity: 1;
  //     border-radius: 20px;
  //   }
  //   .btnsad {
  //     color: #333;
  //     width: 112px;
  //     height: 40px;
  //     background: rgba(255, 195, 44, 1);
  //     opacity: 1;
  //     border-radius: 20px;
  //   }
  // }
  width: 100%;
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  .mark-box {
    height: auto;
    margin-top: 16px;
    margin-left: 20px;
    .mark {
      // width: 72px;
      width: auto;
      height: 28px;
      padding: 0 12px;
      background: #f6f7f9;
      border-radius: 28px 28px 28px 28px;
      border: 1px solid #d6d6d6;
      margin-right: 16px;
      &.active {
        background: #ffe5e7;
        color: #ff0015;
      }
    }
  }
  .add-box {
    width: 650px;
    height: 77px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #eeeeee;
    margin-bottom: 29px;
    box-sizing: border-box;
    padding: 12px 20px;
    .img-box img {
      width: 13px;
      height: 13px;
    }
    .text p {
      text-align: left;
      &:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  .btn {
    width: 220px;
    height: 50px;
    background: linear-gradient(90deg, #ed1b23 0%, #f15f64 100%);
    box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
    border-radius: 8px 8px 8px 8px;
    color: #ffffff;
    font-size: 16px;
    margin-left: 29px;
    margin-top: 210px;
  }
  .img {
    width: 83px;
    height: 83px;
  }
  //   表单
  .el-form-item {
    margin-bottom: 0px;
  }
  .demo-ruleForm {
    margin-left: 299px;
    margin-top: 40px;
  }
  .el-form-item__label {
    margin-right: 20px;
  }
  .el-textarea {
    width: 400px;
    height: 160px;
  }
  .el-textarea__inner {
    width: 400px;
    height: 160px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #eeeeee;
  }
  .dialog-box {
    height: 513px;

    .header {
      height: 46px;
      background-color: #e2e2e2;
      padding-top: 13px;
      margin-bottom: 21px;
      .left {
        img {
          width: 4px;
          height: 14px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .right {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .content {
      height: 318px;
      overflow-y: scroll;
      .item {
        img {
          width: 16px;
          height: 16px;
        }
        display: flex;
        margin-left: 123px;
        height: 78px;
        align-items: center;
        margin-bottom: 20px;
        .item-content {
          margin-left: 20px;
          width: 320px;
          height: 78px;
          background: #ffffff;
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          padding: 12px 20px;
          .line1 {
            margin-bottom: 16px;
          }
        }
      }
      .add-address {
        color: #1ca8fc;
        margin-left: 160px;
      }
      .btn-box {
        margin-left: 110px;
        padding-bottom: 40px;
        .cancle {
          width: 160px;
          height: 50px;
          background: #d5d5d5;
          border-radius: 8px 8px 8px 8px;
          font-size: 16px;
          color: #ffffff;
          margin-right: 60px;
        }
        .confirm {
          width: 160px;
          height: 50px;
          background: linear-gradient(90deg, #ed1b23 0%, #f15f64 100%);
          box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
          border-radius: 8px 8px 8px 8px;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
    .content2 {
      .add-address {
        color: #1ca8fc;
        margin-left: 160px;
      }
      .btn-box {
        margin-left: 110px;
        padding-bottom: 40px;
        .cancle {
          width: 160px;
          height: 50px;
          background: #d5d5d5;
          border-radius: 8px 8px 8px 8px;
          font-size: 16px;
          color: #ffffff;
          margin-right: 60px;
        }
        .confirm {
          width: 160px;
          height: 50px;
          background: linear-gradient(90deg, #ed1b23 0%, #f15f64 100%);
          box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
          border-radius: 8px 8px 8px 8px;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
    //对话框垂直居中
    top: 50% !important;
    transform: translateY(-50%);
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
  }
  // 自定义弹窗样式
  .assderTc-repairform {
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
    display: flex;
    align-items: center;
    .title {
      padding-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 24px;
        right: 32px;
      }
    }
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 0 !important;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    .ja-c {
      height: auto !important;
      margin: 0 auto;
      margin-top: 24px;
      padding: 0 !important;
      .text {
        width: 460px;
        height: 45px;
        font-size: 16px;
        color: #666666;
        line-height: 24px;
        box-sizing: border-box;
        padding: 0 32px;
        // margin-left: 62px;
        text-align: center;
      }
      .con-box {
        margin-bottom: 32px;
        margin-top: 37px;
        width: 460px;
        text-align: center;
      }
      .btn-box {
        margin-bottom: 32px;
        margin-top: 37px;
        width: 460px;
        text-align: center;
      }
      .btn {
        width: 140px;
        height: 42px;
        background: linear-gradient(96deg, #ed1b23 0%, #f15f64 100%);
        box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
        border-radius: 8px 8px 8px 8px;
        color: #ffffff;
        margin: 0px !important;

        // margin-bottom: 32px;
        // margin-left: 160px;
      }
    }

    .btnad {
      width: 112px;
      height: 40px;
      background: rgba(246, 247, 249, 1);
      border: 1px solid #ecedef;
      opacity: 1;
      border-radius: 20px;
    }
    .btnsad {
      color: #333;
      width: 112px;
      height: 40px;
      background: rgba(255, 195, 44, 1);
      opacity: 1;
      border-radius: 20px;
    }
  }
  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
  }
  .el-upload-list__item {
    width: 83px !important;
    height: 83px !important;
  }
  .el-upload-list__item-actions {
    width: 83px !important;
    height: 83px !important;
  }
  .el-upload-list__item-thumbnail {
    width: 83px !important;
    height: 83px !important;
  }
}
</style>